import { ISwiperData, SwiperImageExtension } from '../../shared/interfaces/swiper.interfaces';

const carouselImagePathPrefix = 'assets/images/home-carousel';

const currentFormat = SwiperImageExtension.PNG;

export const CarouselElements: ISwiperData[] = [
  {
    small: {
      image: `${carouselImagePathPrefix}/slide-1/NFTCuzlet-ASIAC-202311-575-alatt${currentFormat}`,
    },
    smallMedium: {
      image: `${carouselImagePathPrefix}/slide-1/NFTCuzlet-ASIAC-202311-576-767${currentFormat}`,
    },
    medium: {
      image: `${carouselImagePathPrefix}/slide-1/NFTCuzlet-ASIAC-202311-768-959${currentFormat}`,
    },
    mediumLarge: {
      image: `${carouselImagePathPrefix}/slide-1/NFTCuzlet-ASIAC-202311-960-1199${currentFormat}`,
    },
    large: {
      image: `${carouselImagePathPrefix}/slide-1/NFTCuzlet-ASIAC-202311-1200-1920${currentFormat}`,
    },
    redirectPath: {
      url: '/mobil-szolgaltatas',
    },
  },
  {
    small: {
      image: `${carouselImagePathPrefix}/slide-2/Slider-Samsung-Galaxy-A16-5G-202411-600-360${currentFormat}`,
    },
    smallMedium: {
      image: `${carouselImagePathPrefix}/slide-2/Slider-Samsung-Galaxy-A16-5G-202411-767-576${currentFormat}`,
    },
    medium: {
      image: `${carouselImagePathPrefix}/slide-2/Slider-Samsung-Galaxy-A16-5G-202411-959-768${currentFormat}`,
    },
    mediumLarge: {
      image: `${carouselImagePathPrefix}/slide-2/Slider-Samsung-Galaxy-A16-5G-202411-1199-960${currentFormat}`,
    },
    large: {
      image: `${carouselImagePathPrefix}/slide-2/Slider-Samsung-Galaxy-A16-5G-202411-1200-1920${currentFormat}`,
    },
    redirectPath: {
      url: '/keszulekek/Samsung%20Galaxy%20A16%205G',
      queryParams: {
        szolgaltatas: 110083345,
        husegIdo: 24,
        futamIdo: 0,
        szin: 0,
      },
    },
  },
  {
    small: {
      image: `${carouselImagePathPrefix}/slide-3/Slider-Xiaomi-Redmi-14C-202411-600-360${currentFormat}`,
    },
    smallMedium: {
      image: `${carouselImagePathPrefix}/slide-3/Slider-Xiaomi-Redmi-14C-202411-767-576${currentFormat}`,
    },
    medium: {
      image: `${carouselImagePathPrefix}/slide-3/Slider-Xiaomi-Redmi-14C-202411-959-768${currentFormat}`,
    },
    mediumLarge: {
      image: `${carouselImagePathPrefix}/slide-3/Slider-Xiaomi-Redmi-14C-202411-1199-960${currentFormat}`,
    },
    large: {
      image: `${carouselImagePathPrefix}/slide-3/Slider-Xiaomi-Redmi-14C-202411-1200-1920${currentFormat}`,
    },
    redirectPath: {
      url: '/keszulekek/Xiaomi%20Redmi%2014C',
      queryParams: {
        szolgaltatas: 110083346,
        husegIdo: 24,
        futamIdo: 0,
        szin: 0,
      },
    },
  },
  {
    small: {
      image: `${carouselImagePathPrefix}/slide-4/Slider-Karacsony-50GB-202411-600-360${currentFormat}`,
    },
    smallMedium: {
      image: `${carouselImagePathPrefix}/slide-4/Slider-Karacsony-50GB-202411-767-576${currentFormat}`,
    },
    medium: {
      image: `${carouselImagePathPrefix}/slide-4/Slider-Karacsony-50GB-202411-959-768${currentFormat}`,
    },
    mediumLarge: {
      image: `${carouselImagePathPrefix}/slide-4/Slider-Karacsony-50GB-202411-1199-960${currentFormat}`,
    },
    large: {
      image: `${carouselImagePathPrefix}/slide-4/Slider-Karacsony-50GB-202411-1200-1920${currentFormat}`,
    },
    redirectPath: {
      url: '/mobil-szolgaltatas',
    },
  },
  {
    small: {
      image: `${carouselImagePathPrefix}/slide-5/Slider-Karacsony-100GB-202411-600-360${currentFormat}`,
    },
    smallMedium: {
      image: `${carouselImagePathPrefix}/slide-5/Slider-Karacsony-100GB-202411-767-576${currentFormat}`,
    },
    medium: {
      image: `${carouselImagePathPrefix}/slide-5/Slider-Karacsony-100GB-202411-959-768${currentFormat}`,
    },
    mediumLarge: {
      image: `${carouselImagePathPrefix}/slide-5/Slider-Karacsony-100GB-202411-1199-960${currentFormat}`,
    },
    large: {
      image: `${carouselImagePathPrefix}/slide-5/Slider-Karacsony-100GB-202411-1200-1920${currentFormat}`,
    },
    redirectPath: {
      url: '/mobil-szolgaltatas',
    },
  },
];
